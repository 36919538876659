





















































import Vue from 'vue'
import store from '@/store'
import moment from 'moment'
// import request from '@/utils/request'
import storage from '@/store/storage'
const tabList = [{
    id: '',
    text: '全部',
    imgUrl: '/static/images/source/all_1.png',
    imgUrlAcyive: '/static/images/source/all.png',
},{
    id: '0',
    text: '待审核',
    imgUrl: '/static/images/source/wait_1.png',
    imgUrlAcyive: '/static/images/source/wait.png',
},{
    id: '1',
    text: '驳回',
    imgUrl: '/static/images/source/reject_1.png',
    imgUrlAcyive: '/static/images/source/reject.png',
},{
    id: '2',
    text: '审核通过',
    imgUrl: '/static/images/source/pass_1.png',
    imgUrlAcyive: '/static/images/source/pass.png',
},{
    id: '3',
    text: '已生成二维码',
    imgUrl: '/static/images/source/ewm_1.png',
    imgUrlAcyive: '/static/images/source/ewm.png',
}]
export default Vue.extend({
  data() {
    return {
        batchName: '',
        tabList,
        examineStatus: '',
        loading: false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        list: [],
        tab: 0,
    }
  },
  computed: {
    current(): any {
        return this.$store.state.current
    },
  },
  async created() {
    this.getData()
  },
  methods: {
    changeTab(id: any) {
      this.examineStatus = id
      this.pageNum = 1
      this.getData()
    },
    onLoad() {
      this.pageNum += 1
      this.getData()
    },
    getData() {
        this.loading = true
        const { examineStatus } = this
        // const { pageNum, pageSize, batchName, examineStatus } = this
        // const params = { pageNum, pageSize, batchName, examineStatus } as any
        // // console.log(params)
        // request.get('/api/tea/rest_tracing_source/page', params).then(({ list, total }) => {
        //     if (pageNum == 1) this.list = list
        //     else this.list = this.list.concat(list)
        //     this.total = total
        //     this.loading = false
        // })

        // storage.remove('sourceItem') 
        const list = [{
            batchName: '第1批次：0001', 
            time: '2024-06-20',
            examineStatus: 0,
            id: '0001', }, {
            batchName: '第2批次：0002', 
            time: '2024-06-22',
            examineStatus: 2,
            id: '0002', }, {
            batchName: '第3批次：0003', 
            time: '2024-06-26',
            examineStatus: 2,
            id: '0003', }, {
            batchName: '第4批次：0004', 
            time: '2024-06-30',
            examineStatus: 3,
            id: '0004', }, 
        ]
        const sourceItem = JSON.parse(storage.get('sourceItem'))
        // console.log(sourceItem)
        if(sourceItem) {
            list.push(...JSON.parse(JSON.stringify(sourceItem)))
        }
        if (examineStatus === '') {
            this.list = list
        } else {
            this.list = list.filter((e: any) => String(e.examineStatus) === examineStatus)
        }
        this.loading = false
    },
    bindEnter() {
        this.$router.push(`/stateDetailEdit`)
    },
    view(item: any) {
        const data = { state: item.examineStatus, id: item.id }
        if (data.state == 3) {
            this.$router.push(`/stateDetailEwm?data=${JSON.stringify(data)}`)
        // } else if (data.state == 0 || data.state == 2) {
        //     this.$router.push(`/stateDetailView?data=${JSON.stringify(data)}`)
        // } else {
        //     this.$router.push(`/stateDetailEdit?data=${JSON.stringify(data)}`)
        }
    },
    register() {
        this.$router.push(`/register`)
    },
  },
})
